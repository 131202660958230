<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Account Summary">
            <div class="row">
                <div class="col-md-6 d-flex align-items-stretch">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Daily Growth</h4>
                        </div>
                        <div class="card-body">
                            <daily-growth></daily-growth>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-stretch">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Daily Drawdown (From Monthly HWM)</h4>
                        </div>
                        <div class="card-body">
                            <daily-drawdown height="250px"></daily-drawdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex align-items-stretch">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Summary</h4>
                        </div>
                        <div class="card-body">
                            <account-summary></account-summary>
                        </div>
                    </div>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import DailyGrowth from '@/widgets/DailyGrowth';
import DailyDrawdown from '@/widgets/DailyDrawdown';
import AccountSummary from '@/widgets/AccountSummary';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        DailyGrowth,
        DailyDrawdown,
        AccountSummary
    }
}
</script>