<template>
    <div class="account-summary-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
            </div>
        </div>
        <b-table id="trades-table" :items="filterItems()" :fields="columns" responsive class="text-center" show-empty :per-page="50" :current-page="currentPage" sort-by="date" :sort-desc="true">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="50" aria-controls="trades-table"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'account-summary',
    data() {
        let that = this;

        let columns = [
            {
                key: 'date',
                label: 'Date',
                sortable: true,
                formatter: function(value) {
                    return format(value, 'dd/MM/yyyy');
                }
            },
            {
                key: 'dailyReturn',
                label: 'Return',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                }
            },
            {
                key: 'growth',
                label: 'Cumulative Return',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                }
            },
            {
                key: 'volume',
                label: 'Volume',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.number(value, 2, true);
                }
            },
            {
                key: 'sodEquity', 
                label: 'SOD Equity',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.currency(that.$options.filters.number(parseFloat(value) / 100));
                } 
            },
            {
                key: 'eodEquity',
                label: 'EOD Equity',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.currency(that.$options.filters.number(parseFloat(value) / 100));
                }
            },
            {
                key: 'highWatermark',
                name: 'Monthly Highwatermark',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                }
            },
            {
                key: 'fees',
                label: 'Fees',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.currency(that.$options.filters.number(parseFloat(value) / 100));
                }
            },
            {
                key: 'transfers',
                label: 'Transfers',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.currency(that.$options.filters.number(parseFloat(value) / 100));
                }
            }
        ];

        let filters = {};
        for(let column of columns) {
            filters[column.key] = '';
        }

        return {
            columns: columns,
            items: [],
            filters: filters,
            currentPage: 1,
            hasSetupListeners: false
        };
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('accountsummary', this.onAccountSummary);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('accountsummary', this.onAccountSummary);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'AccountSummary',
                args: {}
            });
        },
        onAccountSummary(event) {
            let rows = [];

            for(let date in event.response) {
                let row = {
                    date: new Date(date)
                };

                for(let key in event.response[date]) {
                    row[key] = event.response[date][key];
                }

                rows.push(row);
            }

            this.items = rows;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.items, clone);

            for(let item of items) {
                item.date = format(item.date, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'Account Summary');
        }
    }
}
</script>